import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to Make a Podcast Intro"
          description="Learn how to make a into that will hook your listeners, introduce them to your brand, assert your identity, and make are big first impression"
        />

        <article>
          <h1>How to Make a Podcast Intro</h1>

          <p>
            One of the first - and most fun - things you should do before you can start putting out your podcast is
            creating your intro. This is where you’ll hook your listeners, introduce them to your brand, assert your
            identity, and make your first impression - so it’s a pretty big deal worth investing thought and effort
            into.
          </p>
          <p>This article offers tips for how you can create an effective podcast intro.</p>
          <h2>Content</h2>
          <img
            src="/images/podcast-intro.jpg"
            alt="Podcast Music Intro"
          />
          <p>
            Let’s start with the most important question: what should you say in your podcast intro? Here are the
            essential elements.
          </p>
          <p>
            First, offer your name. It doesn’t even necessarily have to be your real name; you can choose a “pen name”
            of sorts for your podcast, but it’s important to identify yourself to create a personal connection with
            listeners. This is especially crucial in the podcast format because your audience is missing the visual cues
            that typically help you to identify a new person. You can also let your audience know who you are by giving
            a title, such as your job/role.
          </p>
          <p>
            Then give the name of your podcast. This is important to reinforce your branding as well as just to make
            sure that your audience is aware of where they are.
          </p>
          <p>
            Offer a brief description of the podcast, as well, so that your listeners have a sense of what they can
            expect from the show. This can be in the form of a tagline, a motto, a canned purpose statement, whatever
            you’d like.
          </p>
          <p>
            Now it’s time to introduce this particular episode of the podcast. Give the name of the episode as well as
            its number (if you’d like). Offer a quick description or preview of what the episode will be about and what
            your listeners can expect to receive from it. This is your chance to hook people in, to convince them to
            keep listening and to start setting the tone for the rest of the podcast.
          </p>
          <h2>Music and Sound Effects</h2>
          <img
            data-src="/images/music-and-sound.jpg"
            alt="Podcast Music and sound effects with headphones"
            className="lazyload"
          />
          <p>
            If your entire podcast is only comprised of voices talking, it risks starting to sound a little monotonous
            and boring. That’s where background music & sound effects come in. These do a lot of important things like:
          </p>
          <ul>
            <li>Add interest</li>

            <li>Break up a lot of talking</li>

            <li>Set a mood</li>

            <li>Emphasize certain points</li>

            <li>Create a soundscape to help the listener feel absorbed into the world of the podcast</li>
          </ul>
          <p>
            When it comes to your intro, using a recurring jingle or sound effect can help brand your podcast and cue
            your audience to settle in for the episode.
          </p>
          <p>
            When you are choosing music and sound effects, it’s important to consider issues of copyright and legality.
            You can’t just pull any old sound off of the internet and use it. For an easy option, you can search for
            music and sound effects on royalty-free libraries, some of which are free and some of which allow you to buy
            the rights to the sound at a reasonable price.
          </p>
          <p>
            Or, if you can afford it, you can have somebody create musical flourishes, jingles, and sound effects
            specifically for your podcast, which will lend you professionalism and uniqueness to help you stand out from
            your competitors.
          </p>
          <h2>Duration</h2>

          <p>
            When it comes to an intro, the shorter, the better. You don’t want to lose your audience’s attention before
            your episode even gets going. So use a tempo that is slow enough to understand but not so slow as to bore
            everybody right off the bat.
          </p>
          <p>
            If you can, try to keep the entire intro between 15 seconds and 1 minute in length. Any more than a minute
            and you’re going to start losing people.
          </p>
          <h2>Sample Intro</h2>

          <p>
            Here’s an example of how all of these elements come together to create a concise, effective podcast intro:
          </p>
          <code>
            “Welcome to ___(podcast name)____, I’m _____(your name)_____. Every week, we ______(brief
            description)______.
            Today, we’ll _____(episode description)______. (Musical flourish).”
          </code>
          <hr/>
          <h2>Making the Intro</h2>

          <p>
            Now let’s get practical. Here are the steps you need to take, beginning to end, to create your podcast
            intro.
          </p>
          <p>
            First, write your script. Listen to your favourite podcasts for ideas and inspiration, and then take the
            time to write out exactly what you want to say; the intro is not the place for improvisation.
          </p>
          <p>
            For finding and downloading royalty-free music and sound effects, you can look to the following resources:
          </p>
          <ul>
            <li>
              <a href="http://www.pond5.com/">Pond5</a>
            </li>

            <li>
              <a href="https://audiojungle.net/tags/podcast">AudioJungle</a>
            </li>

            <li>
              <a href="http://freemusicarchive.org/">Free Music Archive</a>
            </li>

            <li>
              <a href="http://www.beatsuite.com/">Beat Suite</a>
            </li>

            <li>
              <a href="https://www.soundsnap.com/">Soundsnap</a>
            </li>

            <li>
              <a href="http://www.freesound.org/">Freesound</a>
            </li>
          </ul>
          <p>
            If you’re going the route of having somebody create a jingle for you, consider freelance work marketplaces
            such as:
          </p>
          <ul>
            <li>
              <a href="https://www.fiverr.com/gigs/search?utf8=%E2%9C%93&order=latest&search_in=everywhere&category=all&query=podcast+intro&x=-910&y=-71&page=1&layout=auto">
                Fiverr
              </a>
            </li>

            <li>
              <a href="https://www.upwork.com/">Upwork</a>
            </li>
          </ul>
          <p>
            And for the final step of recording and editing your podcast intro, putting all of the elements together,
            you can use such tools as:
          </p>
          <ul>
            <li>Garage Band</li>

            <li>Logic Pro</li>

            <li>Adobe Audition</li>

            <li>Audacity</li>
          </ul>
          <p>
            Take your time to make sure that everything from audio levels to timing and pacing is perfect. After all,
            first impressions are everything. Good luck!
          </p>
        </article>
      </Layout>
    )
  }
}

export default Page
